





























import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import Spinners from "@/components/utilities/Spinners.vue";
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard.vue";
import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        Spinners,
        ExhibitorLogoCard,
        ExhibitorResultCard
    }
})
export default class SingleSponsorDisplay extends Vue {
    isLoading = true;
    featuredTextColor = "";
    featuredHeaderAlignment = "";

    get tradeshowCompanies() {
        const title = tradeshowStore.tradeshowCompanies.title;
        const partner = tradeshowStore.tradeshowCompanies.partner;
        const premier = tradeshowStore.tradeshowCompanies.premier;

        // this doesnt matter what sponsor type
        return [...title, ...partner, ...premier].filter(
            (item: any) => item.forPromo
        );
    }
    get cssVariables() {
        this.featuredTextColor = this.$store.getters.cssVariables[
            "--home-solutionProvider-text-color"
        ]
            ? this.$store.getters.cssVariables[
                  "--home-solutionProvider-text-color"
              ]
            : "text-white";
        return this.$store.getters.cssVariables;
    }
    get headerAlignment(): string {
        return this.$store.getters.getPageOptions("home").section
            .featuredSolutionProvider.labelHeaderAlignment
            ? "text-center"
            : "";
    }
    get homeLabel() {
        return this.$store.getters.homeLabel;
    }

    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
    }
}
