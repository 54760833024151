var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.optionsReady)?_c('div',{staticClass:"px-8 md:px-0"},[_c('div',{ref:"prev-button",class:("swiper-button-prev swiper-button-prev_" + _vm.componentId + " text-accent"),attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('swiper',{ref:"streamSlider",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.sessionData),function(session){return _c('swiper-slide',{key:session.id,staticClass:"mg-swiper-slide"},[_c('button',{staticClass:"bg-white border mx-2 w-full p-0 flex flex-col",on:{"click":function($event){return _vm.handleItemClick(session)}}},[(session.image)?_c('div',{staticClass:"w-full"},[_c('img',{staticClass:"block w-full",attrs:{"src":session.image,"alt":session.title}})]):_vm._e(),(!session.hideTitle && session.title)?_c('div',{staticClass:"p-4 text-left w-full",class:{
                            'border-t': session.image
                        }},[_c('h3',{staticClass:"text-primary text-lg mb-0"},[_vm._v(" "+_vm._s(session.title)+" ")])]):_vm._e()])])}),1),_c('div',{ref:"next-button",class:("swiper-button-next swiper-button-next_" + _vm.componentId + " text-accent"),attrs:{"slot":"button-next"},slot:"button-next"})],1):_vm._e(),_c('b-modal',{directives:[{name:"addbuefymodal",rawName:"v-addbuefymodal"}],attrs:{"active":_vm.isModalOpen,"trap-focus":"","width":700},on:{"update:active":function($event){_vm.isModalOpen=$event}}},[(_vm.showActiveListOnModal && _vm.showActiveListOnModal.image)?_c('div',{staticClass:"flex justify-center w-full"},[_c('div',{staticClass:"w-full"},[_c('img',{staticClass:"block w-full h-full max-h-full",class:{
                        'object-contain': _vm.activeItemIsSponsor,
                        'p-4 md:p-8': _vm.activeItemIsSponsor,
                        'object-cover': !_vm.activeItemIsSponsor
                    },attrs:{"src":_vm.showActiveListOnModal.image,"alt":_vm.showActiveListOnModal.title}})])]):_vm._e(),(_vm.showActiveListOnModal)?_c('div',{staticClass:"p-4 md:p-8"},[_c('h2',{staticClass:"text-lg md:text-xl"},[_vm._v(" "+_vm._s(_vm.showActiveListOnModal.title)+" ")]),_c('div',{staticClass:"flex flex-col md:flex-row justify-between"},[(_vm.showActiveListOnModal.url)?_c('a',{staticClass:"button pill-button is-primary has-text-weight-bold is-uppercase mt-3 mb-4",attrs:{"href":_vm.showActiveListOnModal.url}},[_vm._v(" View item ")]):(
                        _vm.showActiveListOnModal.videoUrl ||
                            _vm.showActiveListOnModal.videoUrlOnDemand
                    )?_c('router-link',{staticClass:"button pill-button is-primary has-text-weight-bold is-uppercase mt-3 mb-4",attrs:{"to":{
                        name: 'Session',
                        params: {
                            id: _vm.showActiveListOnModal.sessionId
                        }
                    }}},[_vm._v("Watch "+_vm._s(_vm.activeItemIsSponsor ? "Video" : "Session"))]):_vm._e(),(_vm.showActiveListOnModal.document)?_c('a',{staticClass:"block button pill-button is-primary has-text-weight-bold is-uppercase mt-3 mb-4",attrs:{"target":"_blank","rel":"noopener noreferrer","href":_vm.showActiveListOnModal.document}},[_vm._v("Download Slides")]):_vm._e()],1),_c('div',{staticClass:"pb-4 text-primary"},[_c('div',{staticClass:"my-3"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.showActiveListOnModal.description)}})]),(_vm.speakersString)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"mt-3 border-b pb-4 mb-4"},[_vm._v(" Speakers: "),_c('b',[_vm._v(_vm._s(_vm.speakersString))])]),_c('h3',{staticClass:"text-lg mb-6 mt-0 text-primary"},[_vm._v(" About the Speakers ")]),_vm._l((_vm.showActiveListOnModal.speakers),function(speaker,index){return _c('div',{key:index,staticClass:"mb-6"},[(speaker.id)?_c('h4',{staticClass:"text-lg font-bold  duration-200 transition-transform transition-color ease-in-out leading-tight mb-2"},[_c('router-link',{attrs:{"to":("/attendees/attendee-profile/" + (speaker.id))}},[_vm._v(" "+_vm._s(speaker.name)+" ")])],1):_c('h4',{staticClass:"mb-2 text-lg text-primary"},[_vm._v(" "+_vm._s(speaker.name)+" ")]),(speaker.title)?_c('div',{staticClass:"block mb-3 text-sm font-light"},[_vm._v(" "+_vm._s(speaker.title)+" ")]):_vm._e(),_vm._v(" "+_vm._s(speaker.biography)+" ")])})],2):_vm._e()])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }