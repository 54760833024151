




































































































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";
import { v4 as uuidv4 } from "uuid";
import { Component, Vue, Prop } from "vue-property-decorator";
import { SessionDataObject, FeaturedContentObject } from "@/types/interfaces";

interface ActiveList {
    id?: number;
    sessionId?: string;
    imgPath?: string;
    tittle?: string;
    speakers?: string;
    company?: string;
    description?: string;
    position?: string;
    url?: string;
}

@Component({
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    }
})
export default class SessionSwimLane extends Vue {
    componentId = "";
    isLoading = false;
    isModalOpen = false;
    activeItemIsSponsor = false;
    activeListId = "0";
    optionsReady = false;
    swiperOption = {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false, // there is a bug in loop mode where order gets messed up and modal does not work in some cases
        autoplay: {
            delay: 5000,
            disableOnInteraction: true
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 40
            },
            1336: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 10
            }
        },
        navigation: {}
    };

    @Prop({
        required: true,
        default: []
    })
    sessionData!: Array<object>;

    @Prop({
        required: false,
        default: ""
    })
    contentType?: string;

    get showActiveListOnModal(): ActiveList | SessionDataObject | undefined {
        let lookIn = this.sessionData;

        if (this.activeItemIsSponsor) {
            lookIn = this.sponsorVideos;
        }

        const showCase = lookIn.find((item) => {
            let element;
            if ("featuredContent" === this.contentType) {
                element = item as FeaturedContentObject;
                return element.itemId === this.activeListId;
            } else {
                element = item as SessionDataObject;
                return element.sessionId === this.activeListId;
            }
        });
        return showCase;
    }

    get speakersString() {
        const speakers = this?.showActiveListOnModal?.speakers;
        let returnValue = "";
        if (!speakers || 0 === speakers.length) return returnValue;

        if (Array.isArray(speakers)) {
            returnValue = speakers.map((item) => item.name).join(", ");
        }

        return returnValue;
    }

    get sponsorVideos() {
        return this.$store.getters.sponsorVideos;
    }

    /**
     * Lifecycle
     */
    created() {
        this.componentId = uuidv4();

        // Options depending on componentId
        this.swiperOption.navigation = {
            nextEl: `.swiper-button-next_${this.componentId}`,
            prevEl: `.swiper-button-prev_${this.componentId}`
        };
        this.optionsReady = true;

        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    }

    /**
     * Methods
     */
    handleItemClick(session: SessionDataObject) {
        if (session.itemId && "featuredContent" === this.contentType) {
            this.activeListId = session.itemId;
        } else {
            this.activeListId = session.sessionId;
        }

        if (this.showActiveListOnModal && this.showActiveListOnModal.url) {
            location.href = this.showActiveListOnModal.url;
        } else {
            this.showModal();
        }
    }

    showModal() {
        this.isModalOpen = true;
    }

    closeModal(): boolean {
        return (this.isModalOpen = false);
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }
}
