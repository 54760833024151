import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { api } from "@/services/api";
import Store from "../index";
import { VimeoSessionDataObject } from "@/types/interfaces";

const endpoint = `/api/GetMeetingInfo`;
const requestToken = "xbeAaSHALmTsnou3lkjaWcWDZbICDdRwtRh4HHtF9a9iS6g0Zr4ozw==";

@Module({
    dynamic: true,
    store: Store,
    name: "getMeetingInfo",
    namespaced: true
})
export default class GetMeetingInfoModule extends VuexModule {
    recommendedStreams: Array<VimeoSessionDataObject> = [];

    @Mutation
    public setRecommendedStreams(data: Array<VimeoSessionDataObject>) {
        this.recommendedStreams = data;
    }

    @Action({ commit: "setRecommendedStreams" })
    async getRecommendedStreams() {
        return await api
            .get(endpoint, {
                params: {
                    code: requestToken,
                    meetingId: 1
                }
            })
            .then((res) => {
                const recommendedStreams = [];
                const recordedSessions = res.data.recorded_sessions;
                const recordedTracks = res.data.recorded_tracks;

                for (let i = 0; i < recordedTracks.length; i++) {
                    const sessions =
                        recordedSessions[recordedTracks[i]].sessions;
                    for (let j = 0; j < sessions.length; j++) {
                        if (sessions[j].Featured) {
                            recommendedStreams.push(sessions[j]);
                        }
                    }
                }

                return recommendedStreams;
            });
    }
}
