






















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 150) + "...";
        }
    }
})
export default class StaticPageIcons extends Vue {
    staticCompany = [
        {
            imgPath: require("../../../assets/staticSwimlane/loyola.png"),
            title: "Watch live & on-demand",
            link: "/tradeshow/exhibitor-details/194309"
        },
        {
            imgPath: require("../../../assets/staticSwimlane/caei.png"),
            title: "View a poster",
            link: "/tradeshow/exhibitor-details/217315"
        },
        {
            imgPath: require("../../../assets/staticSwimlane/goteborgs.svg"),
            title: "Create your schedule",
            link: "/tradeshow/exhibitor-details/217520"
        }
    ];
}
