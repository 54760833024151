<template>
    <div>
        <div class="swiper-button-prev text-white" slot="button-prev"></div>
        <swiper
            class="swiper recommended-stream-section"
            ref="streamSlider"
            :options="swiperOption"
        >
            <swiper-slide
                class="mg-swiper-slide"
                v-for="(recommended, index) in recommendedStreams"
                :key="index"
            >
                <router-link
                    v-if="recommended"
                    :to="'/live-stream/' + recommended.Vimeo.video_id"
                    class="is-block relative"
                >
                    <img
                        :src="recommended.Vimeo.thumbnail_url"
                        alt="Thumbnail for"
                        :key="index"
                    />
                    <div
                        class="bottom-0 absolute p-4 pt-8 w-full mg-filter mg-filter--gradient-out-top border-box"
                    >
                        <div class="text-white is-size-6">
                            {{ recommended.Title }}
                        </div>
                    </div>
                </router-link>
            </swiper-slide>
        </swiper>
        <div class="swiper-button-next text-white" slot="button-next"></div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { getModule } from "vuex-module-decorators";
import meetingVuexModule from "@/store/vuex-modules/getMeetingInfo";

const meetingStore = getModule(meetingVuexModule);

export default {
    name: "recommended-streams",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                freeMode: true,
                loop: true,
                loopFillGroupWithBlank: false,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 40
                    },
                    1336: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
        };
    },
    computed: {
        recommendedStreams() {
            return meetingStore.recommendedStreams;
        }
    },
    created() {
        meetingStore.getRecommendedStreams();
    }
};
</script>
<style lang="scss" scoped>
@import "../styles/views/home";
</style>
